import { ThemeSection } from "../components/ThemeSection";
import { classNames } from "../util";

export const HelloSection = ({
  theme,
  setTheme,
}: {
  theme: string;
  setTheme: (value: string) => void;
}) => {
  return (
    <ThemeSection theme={theme} setTheme={setTheme}>
      <div
        className="h-full w-full flex items-center px-10 lg:px-28"
        id="hello"
      >
        <div
          className={classNames(
            '"font-medium text-xl lg:text-4xl 2xl:text-6xl w-11/12 dark:text-white'
          )}
        >
          I like writing clean and simple code to solve complex problems.
          Welcome to my portfolio.
        </div>
      </div>
    </ThemeSection>
  );
};
