import { AnimatePresence, motion } from "framer-motion";
import { ReactNode } from "react";

export const RevealLeft = ({
  children,
  id,
}: {
  children: ReactNode;
  id: number;
}) => {
  return (
    <AnimatePresence mode="wait">
      <motion.div
        initial="hidden"
        whileInView="visible"
        transition={{ duration: 0.5 }}
        variants={{
          visible: { opacity: 1, x: 0 },
          hidden: { opacity: 0, x: 50 },
        }}
        exit={{ opacity: 0, x: 25, transition: { duration: 0.3 } }}
        key={id}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
};
