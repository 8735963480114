import { motion } from "framer-motion";
import { ThemeSection } from "../components/ThemeSection";

export const BannerSection = ({
  theme,
  setTheme,
}: {
  theme: string;
  setTheme: (value: string) => void;
}) => {
  return (
    <ThemeSection theme={theme} setTheme={setTheme}>
      <div
        className="w-full h-full flex justify-center items-center"
        id="banner"
      >
        <div className="mt-[-130px]">
          <div className="flex">
            {"HEY, I’M".split("").map((char, index) => (
              <div
                className="relative overflow-hidden p-[2px] sm:p-1"
                key={index}
              >
                <motion.div
                  initial={{ y: 175 }}
                  animate={{ y: 0 }}
                  transition={{
                    duration: 0.8,
                    ease: [0, 0.55, 0.45, 1],
                    delay: 1.4,
                  }}
                >
                  <span className="font-bold text-2xl sm:text-4xl md:text-7xl lg:text-9xl xl:text-[150px]">
                    {char}
                  </span>
                </motion.div>
              </div>
            ))}
          </div>
          <div className="flex ml-20 ">
            {"SYED EMAD".split("").map((char, index) => (
              <div className="relative overflow-hidden p-[2px] sm:p-1">
                <motion.div
                  initial={{ y: 175 }}
                  animate={{ y: 0 }}
                  transition={{
                    duration: 0.8,
                    ease: [0, 0.55, 0.45, 1],
                    delay: 1.4,
                  }}
                >
                  <span className="font-bold text-2xl sm:text-4xl md:text-7xl lg:text-9xl xl:text-[150px]">
                    {char}
                  </span>
                </motion.div>
              </div>
            ))}
          </div>
          <motion.p
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              duration: 0.3,
              delay: 2,
            }}
            className="w-full flex justify-end font-medium  text-[10px] md:text-lg  xl:text-2xl"
          >
            A full time software engineer.
          </motion.p>
        </div>
      </div>
    </ThemeSection>
  );
};
