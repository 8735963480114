import { useMotionValueEvent, useScroll } from "framer-motion";
import React, { ReactNode, useRef } from "react";
import { classNames } from "../util";

export const ThemeSection = ({
  theme,
  setTheme,
  children,
  height = null,
}: {
  theme: string;
  setTheme: (value: string) => void;
  children: ReactNode;
  height?: string | null;
}) => {
  const container = useRef(null);

  let { scrollYProgress } = useScroll({
    target: container,
    offset: ["start center", "end center"],
  });

  useMotionValueEvent(scrollYProgress, "change", (value) => {
    if (value > 0 && value < 1) {
      setTheme(theme);
    }
  });

  return (
    <section
      className={classNames(
        "bg-white dark:bg-[#202020] w-full",
        height ? height : "h-screen"
      )}
      ref={container}
    >
      {children}
    </section>
  );
};
