import { RevealAnimation } from "../animation/RevealAnimation";
import { IMAGES } from "../components/Images";
import { ThemeSection } from "../components/ThemeSection";
export const AboutMe = ({
  theme,
  setTheme,
}: {
  theme: string;
  setTheme: (value: string) => void;
}) => {
  return (
    <ThemeSection height="h-fit" theme={theme} setTheme={setTheme}>
      <div
        className="h-full w-full flex flex-col sm:flex-row px-10 sm:px-28 z-0"
        id="about"
      >
        <div className="w-full sm:w-1/2 space-y-16 pb-14  ">
          <div className="sticky space-y-16 top-0">
            <RevealAnimation>
              <h1 className="text-4xl lg:text-5xl xl:text-7xl font-bold dark:text-white">
                About me
              </h1>
            </RevealAnimation>
            <RevealAnimation>
              <p className="text-xl lg:text-2xl xl:text-2xl w-4/5 font-medium dark:text-white">
                With 4 years of development experience. <br></br>
                <br></br>I've worked with various companies like Systems and
                Astera.<br></br>
                <br></br>
                My current role at Idenfo involved designing, building, and
                leading the development of diverse applications.
              </p>
            </RevealAnimation>
            <RevealAnimation>
              <p className="text-[#81817D] text-xl xl:text-2xl">
                I prioritize continuous learning.
              </p>
            </RevealAnimation>
          </div>
        </div>
        <div className="sm:p-4 flex sm:w-1/2 items-end justify-end sticky top-0">
          <img
            src={IMAGES.profile_pic1}
            className="h-full object-cover sticky top-0"
            alt="img"
          />
        </div>
      </div>
    </ThemeSection>
  );
};
