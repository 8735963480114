import { ThemeSection } from "./ThemeSection";

const Footer = ({
  theme,
  setTheme,
}: {
  theme: string;
  setTheme: (value: string) => void;
}) => {
  return (
    <ThemeSection theme={theme} setTheme={setTheme}>
      <div
        className="overflow-hidden px-10 py-14 md:px-20 md:py-24   lg:px-28 lg:py-32 flex flex-col justify-between h-full"
        id="contact"
      >
        <div className="space-y-5">
          <h1 className="font-bold text-2xl md:text-4xl lg:text-8xl dark:text-white ">
            You can connect at
          </h1>
          <div className="flex flex-col sm:flex-row sm:space-x-5 sm:space-y-0 space-y-5">
            <div className="px-3 py-2 bg-white border-2 text-sm md:text-md lg:text-lg rounded-md border-black dark:text-white dark:bg-[#202020]">
              syedemad2021@gmail.com
            </div>
            <div className="px-3 py-2 bg-white border-2 text-sm md:text-md lg:text-lg rounded-md border-black dark:text-white dark:bg-[#202020]">
              +923213801992
            </div>
          </div>
        </div>
        <div className="flex justify-between">
          <div className="flex space-x-5">
            <a
              href="https://www.linkedin.com/in/syed-emad-ul-haq/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Linkedin
            </a>
            <a
              href="https://github.com/syed-emad"
              target="_blank"
              rel="noopener noreferrer"
            >
              GitHub
            </a>
          </div>
          <div className="">
            <p>Made by Me</p>
          </div>
        </div>
      </div>
    </ThemeSection>
  );
};

export default Footer;
