import { useEffect, useRef, useState } from "react";
import { ThemeSection } from "./ThemeSection";
import { motion, useScroll, useTransform } from "framer-motion";
import { IMAGES } from "./Images";

export const Projects = ({
  theme,
  setTheme,
}: {
  theme: string;
  setTheme: (value: string) => void;
}) => {
  const ref = useRef(null);
  let { scrollYProgress } = useScroll({
    target: ref,
    offset: ["0 1", "1.33 1"],
  });
  const scale = useTransform(scrollYProgress, [0, 0.7], [1, 0.65]);
  const [translateYValue, setTranslateYValue] = useState(0);
  const translateY = useTransform(
    scrollYProgress,
    [0, 0.7],
    [10, translateYValue]
  );

  useEffect(() => {
    const handleResize = () => {
      const windowHeight = window.innerHeight;
      // Adjust the factor as needed based on your requirements
      const factor = 0.025;
      const adjustedValue = windowHeight * factor;
      setTranslateYValue(adjustedValue);
    };

    handleResize(); // Initial calculation

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <ThemeSection height="h-fit" theme={theme} setTheme={setTheme}>
        <div className="sm:space-y-16 overflow-hidden" id="projects">
          <h1 className="px-10 sm:px-28 text-black font-extrabold text-4xl lg:text-5xl xl:text-6xl text-center dark:text-white">
            Each Project <br></br>has a story.
          </h1>
          <p className="px-10 sm:px-28 text-black font-medium text-xs sm:text-lg md:text-xl lg:text-2xl text-center dark:text-white">
            I love working on projects that solve particular problems,
            <br></br>
            whether it's an online education system or an analytics dashboard.
            <br></br>
            The possibilities are endless and exciting.
          </p>

          <div className="flex flex-col items-center">
            <motion.div
              style={{ scale: scale, translateY: translateY }}
              className="w-full sm:w-3/4"
              ref={ref}
            >
              <video autoPlay loop muted>
                <source src={IMAGES.funnelshort} type="video/mp4" />
              </video>
              <div className="flex w-full justify-between pr-6">
                <div>
                  <p className="text-white mt-1 text-xs sm:text-md md:text-2xl">
                    DIGITAL MARKETING
                  </p>
                  <h1 className="text-white mt-1 text-2xl sm:text-4xl md:text-6xl font-bold">
                    FUNNEL
                  </h1>
                </div>
                <div className="flex flex-col text-left w-2/4 items-end">
                  <p className="text-white mt-1 text-xs sm:text-md md:text-2xl">
                    Made at © 2024
                  </p>
                  <p className="text-white mt-1 text-xs sm:text-md md:text-2xl text-right">
                    Framer, Tailwind and <br></br>React TypeScript.
                  </p>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </ThemeSection>
    </>
  );
};
