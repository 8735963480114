import { useEffect, useState } from "react";
import "./App.css";
import "./sass/main.scss";
import { BannerSection } from "./sections/BannerSection";
import { AboutMe } from "./sections/AboutMe";
import { SvgLayout } from "./components/Svgs/SvgLayout";
import { Projects } from "./components/Projects";
import { Project2 } from "./components/Project2";
import { Project23 } from "./components/Project23";
import { SomeOtherProjects } from "./components/SomeOtherProjects";
import Footer from "./components/Footer";
import { Technologies } from "./sections/Technologies";
import { HelloSection } from "./sections/HelloSection";
import { useScroll, useMotionValueEvent } from "framer-motion";
import { Header } from "./components/Header";
import mixpanel from "mixpanel-browser";


const projectToken = process.env.REACT_APP_MPP;


mixpanel.init(projectToken!, {
  debug: true,
  track_pageview: true,
  persistence: "localStorage",
});

function App() {
  const [theme, setTheme] = useState("light");
  const { scrollYProgress } = useScroll({});
  const [show, setShow] = useState(false);
  useMotionValueEvent(scrollYProgress, "change", (value) => {
    console.log(value);
    if (value > 0.06) {
      console.log("hi");
      setShow(true);
    } else {
      console.log("hiok");
      setShow(false);
    }
  });
  
  useEffect(() => {
    const fetch = async () => {
      mixpanel.track("User visited");
    }
    fetch();
  },[])
  return (
    <>
      <div className={`${theme}`}>
        {/* {show && <NavMenu />} */}
        <SvgLayout>
          <Header />
          <BannerSection theme={"light"} setTheme={setTheme} />
          <HelloSection theme={"dark"} setTheme={setTheme} />
          <AboutMe theme={"light"} setTheme={setTheme} />
          <Technologies theme={"light"} setTheme={setTheme} />
          <Projects theme={"dark"} setTheme={setTheme} />
          <Project2 theme={"dark"} setTheme={setTheme} />
          <Project23 theme={"dark"} setTheme={setTheme} />
          <SomeOtherProjects theme={"dark"} setTheme={setTheme} />
          <Footer theme={"light"} setTheme={setTheme} />
        </SvgLayout>
      </div>
    </>
  );
}

export default App;
