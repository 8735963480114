import { useEffect, useRef, useState } from "react";
import { RevealAnimation } from "../animation/RevealAnimation";
import { RevealLeft } from "../animation/RevealLeft";
import { RevealRight } from "../animation/RevealRight";
import { IMAGES } from "../components/Images";
import { ThemeSection } from "../components/ThemeSection";
import { motion } from "framer-motion";
import { classNames } from "../util";

let areas = ["Backend", "Frontend", "Databases", "DevOps", "Languages"];
let technologyData = [
  {
    id: 0,
    area: "Backend",
    stacks: [
      {
        id: 5,
        name: "Node",
        icon: IMAGES.node_icon,
        description: "Around 3 years of experience working in Node.",
      },
      {
        id: 6,
        name: "Nest",
        icon: IMAGES.nest_icon,
        description: "Looking into Nest for my new projects.",
      },
      {
        id: 7,
        name: "SpringBoot",
        icon: IMAGES.spring_boot_icon,
        description: "Used Spring for 2 large scale projects.",
      },
    ],
  },
  {
    id: 1,
    area: "Frontend",
    stacks: [
      {
        id: 0,
        name: "React",
        icon: IMAGES.react_icon,
        description: "Around 3 years of experience working in React.",
      },
      // {
      //   id: 1,
      //   name: "Next",
      //   icon: IMAGES.next_icon,
      //   description: "Looking into Nest for my new projects.",
      // },
      {
        id: 1,
        name: "Redux Toolkit",
        icon: IMAGES.redux_icon,
        description: "Goto state management tool for React applications",
      },
    ],
  },
  {
    id: 2,
    area: "Databases",
    stacks: [
      {
        id: 8,
        name: "MySQL",
        icon: IMAGES.mysql_icon,
        description: "Worked with MySql for Spring Boot application",
      },
      {
        id: 9,
        name: "Postgres",
        icon: IMAGES.postgres_icon,
        description: "Worked with Postgres for Spring Boot application.",
      },
      {
        id: 10,
        name: "Mongo",
        icon: IMAGES.mongo_icon,
        description: "Have worked with mongo for most Node project",
      },
    ],
  },
  {
    id: 3,
    area: "DevOps",
    stacks: [
      {
        id: 12,
        name: "EC2",
        icon: IMAGES.aws_ec2_icon,
        description: "My goto for server deployment",
      },
      {
        id: 13,
        name: "S3",
        icon: IMAGES.aws_s3_icon,
        description: "My goto for application object storage",
      },
      {
        id: 14,
        name: "Lamda",
        icon: IMAGES.aws_lamda_icon,
        description: "Lamda and sls is my goto comibination for serveless .",
      },
    ],
  },
  {
    id: 4,
    area: "Languages",
    stacks: [
      {
        id: 15,
        name: "Javascript",
        icon: IMAGES.javascript_icon,
        description: "3 year experience with JavaScript",
      },
      {
        id: 16,
        name: "Java",
        icon: IMAGES.java_icon,
        description: "1 year experience with Java",
      },
      {
        id: 17,
        name: "TypeScript",
        icon: IMAGES.typescript_icon,
        description: "2 year experience with TypeScript",
      },
    ],
  },
];

export const Technologies = ({
  theme,
  setTheme,
}: {
  theme: string;
  setTheme: (value: string) => void;
}) => {
  const [data, setData] = useState(technologyData[0]);

  const handleSelectTechs = (key: string) => () => {
    let currentStacks = technologyData.filter(
      (technology) => technology.area === key
    )[0];
    console.log(currentStacks);
    setData(currentStacks);
  };
  const carousal = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);
  useEffect(() => {
    if (carousal.current?.scrollWidth)
      setWidth(carousal.current.scrollWidth - carousal.current.offsetWidth);
  }, []);
  return (
    <ThemeSection height="h-fit md:h-screen" theme={theme} setTheme={setTheme}>
      <div
        className="h-full px-10 sm:px-28 flex flex-col py-28 space-y-14 justify-center"
        id="tech"
      >
        <div className="space-y-10">
          <RevealAnimation>
            <h1 className="font-bold text-2xl lg:text-5xl text-black">
              Tech I’m experienced in
            </h1>
          </RevealAnimation>
          <RevealAnimation>
            <p className="font-medium text-sm md:text-xl lg:text-2xl xl:text-3xl overflow-hidden">
              These are my go-to tech stack to make any project happen. I'm
              passionate about exploring new technologies and honing my
              development skills.
            </p>
          </RevealAnimation>
        </div>
        <div className="flex flex-col xl:flex-row xl:space-x-20 w-full ">
          <div className="flex flex-col ">
            {areas?.map((area) => (
              <RevealRight>
                <motion.p
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={handleSelectTechs(area)}
                  className={classNames(
                    area === data?.area ? "text-black" : "text-[#818080]",
                    "font-bold text-xl sm:text-3xl  mb-4 cursor-pointer"
                  )}
                >
                  {area}
                </motion.p>
              </RevealRight>
            ))}
          </div>
          <div className="w-0 sm:w-2 bg-[#D9D9D9] dark:opacity-25 border-r-4" />
          <div className="">
            <motion.div
              ref={carousal}
              className="h-full flex overflow-hidden flex-row sm:items-center space-y-10 sm:space-y-10 sm:space-x-10 w-full "
            >
              <motion.div
                drag="x"
                dragConstraints={{ right: 0, left: -width }}
                className="flex space-x-5 "
              >
                {data?.stacks?.map((technology) => (
                  <RevealLeft id={technology.id}>
                    <div
                      key={technology.id}
                      className="bg-[#F6F6F6] dark:bg-black dark:border-2 dark:border-white dark:border-opacity-25 rounded-sm h-60 w-60 flex items-center"
                    >
                      <div className="px-5 space-y-2">
                        <img
                          src={technology.icon}
                          alt="node"
                          className="h-9 dark:text-white"
                          style={{ fill: "currentColor" }}
                        />

                        <h1 className="font-semibold text-lg dark:text-white">
                          {technology.name}
                        </h1>
                        <p className="text-sm dark:text-white">
                          {technology.description}
                        </p>
                      </div>
                    </div>
                  </RevealLeft>
                ))}
              </motion.div>
            </motion.div>
          </div>
        </div>
      </div>
    </ThemeSection>
  );
};
