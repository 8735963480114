import { useEffect, useRef } from "react";
import { ThemeSection } from "./ThemeSection";
import { animate, motion, useMotionValue } from "framer-motion";
import { IMAGES } from "./Images";

export const SomeOtherProjects = ({
  theme,
  setTheme,
}: {
  theme: string;
  setTheme: (value: string) => void;
}) => {
  const ref = useRef(null);
  const width = window.innerWidth;
  // infinite scroll
  const xTranslation = useMotionValue(0);
  useEffect(() => {
    let finalPosition = -width / 2 - 8;
    let controls = animate(xTranslation, [0, finalPosition], {
      ease: "linear",
      repeat: Infinity,
      repeatType: "loop",
      duration: 25,
      repeatDelay: 0,
    });
    return controls.stop;
  }, [xTranslation, width]);
  return (
    <>
      <ThemeSection
        height={"h-fit lg:h-screen"}
        theme={theme}
        setTheme={setTheme}
      >
        <div className="h-full flex flex-col justify-center space-y-8 sm:space-y-16 overflow-hidden ">
          <h1 className="text-black font-extrabold text-4xl md:text-6xl text-center dark:text-white">
            Other <br></br> projects.
          </h1>
          <p className="text-black font-medium text-xs sm:text-md md:text-2xl text-center dark:text-white">
            These are some of the projects <br></br> I have worked on over the
            years.
          </p>
          <div className="w-full flex space-x-5">
            <motion.div
              className="flex space-x-5"
              style={{ x: xTranslation }}
              ref={ref}
            >
              <img
                src={IMAGES.rixxon_thumbnail}
                alt=""
                className=" w-20 h-32 sm:w-40 sm:h-52 md:w-80 md:h-96 bg-white object-cover"
              />
              <img
                src={IMAGES.slic_thumbnail}
                alt=""
                className=" w-20 h-32 sm:w-40 sm:h-52 md:w-80 md:h-96 bg-white object-cover"
              />
              <img
                src={IMAGES.akademi_admin}
                alt=""
                className=" w-20 h-32 sm:w-40 sm:h-52 md:w-80 md:h-96 bg-white object-cover"
              />
              <img
                src={IMAGES.peersanalyticspanel}
                alt=""
                className=" w-20 h-32 sm:w-40 sm:h-52 md:w-80 md:h-96 bg-white object-cover"
              />
              <img
                src={IMAGES.professor_website}
                alt=""
                className=" w-20 h-32 sm:w-40 sm:h-52 md:w-80 md:h-96 bg-white object-cover"
              />
              <img
                src={IMAGES.weatherly_website}
                alt=""
                className=" w-20 h-32 sm:w-40 sm:h-52 md:w-80 md:h-96 bg-white object-cover"
              />
              {/* divide */}
              <img
                src={IMAGES.rixxon_thumbnail}
                alt=""
                className=" w-20 h-32 sm:w-40 sm:h-52 md:w-80 md:h-96 bg-white object-cover"
              />
              <img
                src={IMAGES.slic_thumbnail}
                alt=""
                className=" w-20 h-32 sm:w-40 sm:h-52 md:w-80 md:h-96 bg-white object-cover"
              />
              <img
                src={IMAGES.akademi_admin}
                alt=""
                className=" w-20 h-32 sm:w-40 sm:h-52 md:w-80 md:h-96 bg-white object-cover"
              />
              <img
                src={IMAGES.peersanalyticspanel}
                alt=""
                className=" w-20 h-32 sm:w-40 sm:h-52 md:w-80 md:h-96 bg-white object-cover"
              />
              <img
                src={IMAGES.professor_website}
                alt=""
                className=" w-20 h-32 sm:w-40 sm:h-52 md:w-80 md:h-96 bg-white object-cover"
              />
              <img
                src={IMAGES.weatherly_website}
                alt=""
                className=" w-20 h-32 sm:w-40 sm:h-52 md:w-80 md:h-96 bg-white object-cover"
              />
            </motion.div>
          </div>
        </div>
      </ThemeSection>
    </>
  );
};
