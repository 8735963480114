import { motion } from "framer-motion";

export const Header = () => {
  const handleClickScroll = (section: string) => () => {
    const element = document.getElementById(section);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <motion.div
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{
        duration: 0.3,
        delay: 2,
      }}
      className="w-full h-12 items-center bg-white px-10 justify-between flex"
    >
      <p className="font-semibold  text-xs md:text-sm lg:text-lg xl:text-xl tracking-widest ">
        emad
      </p>
      <div className="flex space-x-5">
        <p
          className="font-semibold text-xs md:text-sm lg:text-lg xl:text-xl infinite cursor-pointer"
          onClick={handleClickScroll("about")}
        >
          About
        </p>
        <p
          className="font-semibold text-xs md:text-sm lg:text-lg xl:text-xl cursor-pointer"
          onClick={handleClickScroll("projects")}
        >
          Projects
        </p>
        <p
          className="font-semibold text-xs md:text-sm lg:text-lg xl:text-xl cursor-pointer"
          onClick={handleClickScroll("contact")}
        >
          Contact
        </p>
      </div>
    </motion.div>
  );
};
