import { ReactNode, useEffect, useState } from "react";
import "./style.scss";
import { AnimatePresence, motion } from "framer-motion";
const anim = (variants: any) => {
  return {
    variants,
    initial: "initial",
    animate: "enter",
    exit: "exit",
  };
};
export const SvgLayout = ({ children }: { children: ReactNode }) => {
  const [dimensions, setDimentions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  useEffect(() => {
    const resize = () => {
      setDimentions({ height: window.innerHeight, width: window.innerWidth });
    };
    window.addEventListener("resize", resize);
    return () => window.addEventListener("resize", resize);
  }, []);
  let text = {
    initial: { opacity: 1, top: "50%" },
    enter: {
      opacity: 0,
      top: "-100px",
      height: "0px",
      transition: {
        duration: 0.7,
        delay: 1,
        ease: [0.76, 0, 0.24, 1],
      },
    },
  };
  return (
    <>
      <div className="curve">
        <motion.div
          {...anim(text)}
          className="  fixed flex flex-col items-center text-white w-full z-20"
        >
          <div className="relative overflow-hidden p-1 ">
            <motion.div
              initial="hidden"
              whileInView="visible"
              transition={{
                duration: 0.5,
                delay: 0.2,
                ease: [0.33, 1, 0.68, 1],
              }}
              variants={{
                hidden: { y: "100%" },
                visible: { y: 0 },
              }}
            >
              <h1 className="font-semibold text-white tracking-wider text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl">
                Welcome
              </h1>
            </motion.div>
          </div>
          <div className="relative overflow-hidden p-1 ">
            <motion.div
              initial="hidden"
              whileInView="visible"
              transition={{
                duration: 0.5,
                delay: 0.2,
                ease: [0.33, 1, 0.68, 1],
              }}
              variants={{
                hidden: { y: "100%" },
                visible: { y: 0 },
              }}
            >
              <h1 className="font-semibold  text-white   text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl">
                Folio 2024
              </h1>
            </motion.div>
          </div>
        </motion.div>
        {dimensions.width > 0 && (
          <>
            <SVG {...dimensions} />
          </>
        )}
        {children}
      </div>
    </>
  );
};

const SVG = ({ width, height }: { width: any; height: any }) => {
  const initialPath = `
    M0 300
    Q${width / 2} 0 ${width} 300
    L${width}  ${height + 300}
    Q${width / 2} ${height + 600} 0 ${height + 300}
    L0 300
    `;
  const targetPath = `
    M0 300
    Q${width / 2} 0 ${width} 300
    L${width}  ${height}
    Q${width / 2} ${height} 0 ${height}
    L0 0
    `;

  const curveVariants = {
    initial: {
      d: initialPath,
    },
    enter: {
      d: targetPath,
      transition: {
        duration: 0.7,
        delay: 1.2,
        ease: [0.76, 0, 0.24, 1],
      },
    },
  };
  const slide = {
    initial: {
      top: "-300px",
    },
    enter: {
      top: "-100vh",
      transition: {
        duration: 0.7,
        delay: 1,
        ease: [0.76, 0, 0.24, 1],
      },
    },
  };
  return (
    <motion.svg {...anim(slide)} className={"z-10"}>
      <motion.path {...anim(curveVariants)}></motion.path>
    </motion.svg>
  );
};
