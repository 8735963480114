import { useRef } from "react";
import { ThemeSection } from "./ThemeSection";
import { motion, useScroll, useTransform } from "framer-motion";
import { IMAGES } from "./Images";

export const Project2 = ({
  theme,
  setTheme,
}: {
  theme: string;
  setTheme: (value: string) => void;
}) => {
  const ref = useRef(null);
  let { scrollYProgress } = useScroll({
    target: ref,
    offset: ["0 1", "1.33 1"],
  });
  const scale = useTransform(scrollYProgress, [0, 0.7], [1, 0.9]);

  return (
    <ThemeSection height="h-fit lg:h-screen" theme={theme} setTheme={setTheme}>
      <div className="flex">
        <div className="w-1/2 flex flex-col space-y-5 md:space-y-10 justify-center pl-10">
          <div className="d">
            <p className="text-white mt-1 text-xs sm:text-md md:text-2xl">
              ONLINE EDUCATION
            </p>
            <h1 className="text-white mt-1 text-2xl sm:text-4xl md:text-6xl font-bold">
              Akademi Tech
            </h1>
          </div>
          <p className="text-white mt-1 text-xs sm:text-md md:text-2xl ">
            Tech: Spring Boot, Node, React, Tailwind, Redux, and <br></br>
            React Query .
          </p>
          <p className="text-white mt-1 text-xs sm:text-md md:text-2xl">
            Made at © 2023
          </p>
        </div>
        <div className="w-1/2 flex items-center ">
          <motion.div style={{ scale: scale }} ref={ref}>
            <video autoPlay loop muted className="rounded-t-xl">
              <source src={IMAGES.akademi_video} type="video/mp4" />
            </video>
          </motion.div>
        </div>
      </div>
    </ThemeSection>
  );
};
