export const IMAGES = {
  profile_pic: require("../assets/syed_emad_1.png"),
  profile_pic_2: require("../assets/syed_emad_2.jpg"),
  react_icon2: require("../assets/react_icon.svg").default,
  node_icon: require("../assets/node_icon.svg").default,
  tailwind_icon: require("../assets/tailwind_icon.svg").default,
  akademi_logo: require("../assets/akademi_logo.svg").default,
  akademi_work_banner_png: require("../assets/akademi_work_banner_png.png"),
  github: require("../assets/github.svg").default,
  linkedin: require("../assets/linkedin.svg").default,
  weather_work_banner: require("../assets/weather_work_banner.png"),
  weatherly_logo: require("../assets/weatherly_logo.png"),
  profile_pic_3: require("../assets/test.png"),
  professor_logo: require("../assets/professor_logo.png"),
  professor_project_banner: require("../assets/professor_project_banner.png"),
  pixelit_project_banner: require("../assets/pixelit_project_banner.png"),
  pixelit_logo: require("../assets/pixelit_logo.png"),
  peers_logo: require("../assets/peers_logo.png"),
  peers_project_banner: require("../assets/peers_project_banner.png"),
  redux_icon: require("../assets/redux_icon.svg").default,
  spring_boot_icon: require("../assets/spring_boot_icon.svg").default,
  typescript_icon: require("../assets/typescript_icon.svg").default,
  star_icon: require("../assets/star_icon.svg").default,
  // profile_pic2: require("../assets/pf_3.jpg"),
  profile_pic1: require("../assets/my_pic_1.webp"),
  profile_pic2: require("../assets/my_pic_2.webp"),
  react_icon: require("../assets/react-icon.svg").default,
  pixelit: require("../assets/pixelit.svg").default,
  akademi_video: require("../assets/akademi_video.mp4"),
  video2: require("../assets/sampl2.mp4"),
  funnel: require("../assets/funnel.mp4"),
  funnelshort: require("../assets/funnelshort.mp4"),
  rixxon_thumbnail: require("../assets/rixxon_thumbnail.png"),
  slic_thumbnail: require("../assets/slic_thumbnail.png"),
  imgshowbanner: require("../assets/imgshowbanner.png"),
  akademi_admin: require("../assets/akademi_admin.png"),
  peersanalyticspanel: require("../assets/peersanalyticpanel.png"),
  professor_website: require("../assets/professor_website.png"),
  weatherly_website: require("../assets/weatherly_website.png"),
  nest_icon: require("../assets/nest_icon.svg").default,
  next_icon: require("../assets/next_icon.svg").default,
  postgres_icon: require("../assets/postgres_icon.svg").default,
  mongo_icon: require("../assets/mongo_icno.svg").default,
  mysql_icon: require("../assets/mysql_icon.svg").default,
  aws_lamda_icon: require("../assets/aws_lamda_icon.svg").default,
  aws_s3_icon: require("../assets/aws_s3_icon.svg").default,
  aws_ec2_icon: require("../assets/aws_ec2_icon.svg").default,
  javascript_icon: require("../assets/javascript_icon.svg").default,
  java_icon: require("../assets/java_icon.svg").default,
};
