import { useRef } from "react";
import { ThemeSection } from "./ThemeSection";
import { motion, useScroll, useTransform } from "framer-motion";
import { IMAGES } from "./Images";

export const Project23 = ({
  theme,
  setTheme,
}: {
  theme: string;
  setTheme: (value: string) => void;
}) => {
  const ref = useRef(null);
  let { scrollYProgress } = useScroll({
    target: ref,
    offset: ["0 1", "1.33 1"],
  });
  const scale = useTransform(scrollYProgress, [0, 0.7], [1, 0.9]);

  return (
    <ThemeSection height="h-fit" theme={theme} setTheme={setTheme}>
      <div className="flex">
        <div className="w-1/2 pl-8">
          <motion.div style={{ scale: scale }} ref={ref}>
            <video autoPlay loop muted>
              <source src={IMAGES.video2} type="video/mp4" />
            </video>
          </motion.div>
        </div>
        <div className="w-1/2 flex flex-col pl-10 pb-14">
          <div className="sticky top-0 md:pt-10 space-y-5 md:space-y-10">
            <div className="">
              <p className="text-white mt-1 text-xs sm:text-md md:text-2xl">
                DIGITAL AGENCY
              </p>
              <h1 className="text-white mt-1 text-2xl sm:text-4xl md:text-6xl font-bold">
                Pixel IT
              </h1>
            </div>
            <p className="text-white mt-1 text-xs sm:text-md md:text-2xl">
              Tech: HTML,JavaScript and CSS.
            </p>
            <p className="text-white mt-1 text-xs sm:text-md md:text-2xl">
              Made at © 2020
            </p>
          </div>
        </div>
      </div>
    </ThemeSection>
  );
};
