import { motion } from "framer-motion";
import React, { ReactNode } from "react";

export const RevealAnimation = ({ children }: { children: ReactNode }) => {
  return (
    <div className="relative overflow-hidden w-full p-1">
      <motion.div
        initial="hidden"
        whileInView="visible"
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, y: 25 },
          visible: { opacity: 1, y: 0 },
        }}
      >
        {children}
      </motion.div>
    </div>
  );
};
